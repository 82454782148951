var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content_box beforetab" }, [
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU06.050"))),
      ]),
      _c("div", { staticClass: "clearfix" }, [
        _c("div", { staticClass: "col_4 float_left" }, [
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.057")))]),
                _c("td", [
                  _vm._v(_vm._s(_vm.limitInfo.gurCatNm)),
                  _vm.expired.length > 0
                    ? _c("span", { staticClass: "color_red" }, [
                        _vm._v(_vm._s(_vm.expired)),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.058")))]),
                _c("td", [_vm._v(_vm._s(_vm.strDt))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.059")))]),
                _c("td", [_vm._v(_vm._s(_vm.endDt))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.060")))]),
                _c("td", [_vm._v(_vm._s(_vm.limitInfo.setlMmcntNm))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.061")))]),
                _c("td", [_vm._v(_vm._s(_vm.limitInfo.setlDdsNm))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.062")))]),
                _c("td", [_vm._v(_vm._s(_vm.limitInfo.curCd))]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col_6 float_right" }, [
          _c("div", { staticClass: "chart_credit_limit" }, [
            _c(
              "div",
              { staticClass: "remark_area", staticStyle: { width: "160px" } },
              [
                _c("dl", [
                  _c("dt", [
                    _vm._v(
                      _vm._s(_vm.$t("msg.SETT010T010.065")) +
                        "(" +
                        _vm._s(_vm.limitInfo.curCd) +
                        ")"
                    ),
                  ]),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm.$ekmtcCommon.changeNumberFormat(
                          _vm.amtInfo.totLimit,
                          { isComma: true, isMinus: true }
                        )
                      )
                    ),
                  ]),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("span", { staticClass: "ico1" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.063")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.amtInfo.useLimit,
                            { isComma: true, isMinus: true }
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", { staticClass: "ico2" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.064")))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "num" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.amtInfo.rstLimit,
                            { isComma: true, isMinus: true }
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._m(1),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "130" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "graph_area", staticStyle: { width: "270px" } },
      [_c("canvas", { attrs: { id: "myChart" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }