<template>
  <div>
    <div class="content_box beforetab">
      <!-- content_box  -->

      <h2 class="content_title">{{ $t('menu.MENU06.050') }}</h2>
      <div class="clearfix">
        <div class="col_4 float_left">
          <table class="tbl_row">
            <colgroup>
              <col width="130"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.SETT010T010.057') }}</th>
                <td>{{ limitInfo.gurCatNm }}<span v-if="expired.length > 0" class="color_red">{{ expired }}</span></td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.058') }}</th>
                <td>{{ strDt }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.059') }}</th>
                <td>{{ endDt }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.060') }}</th>
                <td>{{ limitInfo.setlMmcntNm }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.061') }}</th>
                <td>{{ limitInfo.setlDdsNm }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.062') }}</th>
                <td>{{ limitInfo.curCd }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col_6 float_right">
          <!-- right -->
          <div class="chart_credit_limit">
            <div class="remark_area" style="width:160px;">
              <dl>
                <dt>{{ $t('msg.SETT010T010.065') }}({{ limitInfo.curCd }})</dt>
                <dd>{{ $ekmtcCommon.changeNumberFormat(amtInfo.totLimit, { isComma: true, isMinus: true }) }}</dd>
              </dl>
              <ul>
                <li><span class="ico1"></span> <span>{{ $t('msg.SETT010T010.063') }}</span>   <span class="num">{{ $ekmtcCommon.changeNumberFormat(amtInfo.useLimit, { isComma: true, isMinus: true }) }}</span></li>  <!-- color:#1c69e3; RGB값 : 28, 105, 227  -->
                <li><span class="ico2"></span> <span>{{ $t('msg.SETT010T010.064') }}</span>   <span class="num">{{ $ekmtcCommon.changeNumberFormat(amtInfo.rstLimit, { isComma: true, isMinus: true }) }}</span></li> <!-- color:#a5c8fc; RGB값:165, 200,252-->
              </ul>
            </div>

            <div class="graph_area" style="width:270px;">
              <!-- chart 영역  화면 줄였을때 width:250px; 가 적당 -->
              <canvas id="myChart"></canvas>
            </div><!-- chart 영역 // -->
          </div>
        </div><!-- right  // -->
      </div>
    </div><!-- cpntent_box // -->
  </div><!-- content // -->
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'
import Doughnut from 'vue-chart.js'

export default {
  name: 'CalcIssueLimit',
  components: {},
  extends: Doughnut,
  data: function () {
    return {
      isHidden: true,
      isShowPop: false,
      customComponent: null,
      expired: '', //(Expired)
      limitInfo: { },
      amtInfo: { },
      strDt: '',
      endDt: ''
    }
  },
  created () {
    window.functionOutsideVue(this)
  },
  mounted: function () {
    this.searchData()
  },
  methods: {
    searchData () {
      CalcIssue.getLimitInfo('')
        .then(response => {
          console.log(response)
          const info = response.data
          this.limitInfo = info.limitInfo
          this.amtInfo = info.amtInfo
          this.strDt = this.$ekmtcCommon.getDateToStrDelim(this.$ekmtcCommon.getStrToDate(this.limitInfo.gurStrDt), '.')
          this.endDt = this.$ekmtcCommon.getDateToStrDelim(this.$ekmtcCommon.getStrToDate(this.limitInfo.gurEndDt), '.')
          this.createChart(this.amtInfo)
        })
        .catch(err => {
          console.log(err)
        })
    },
    createChart (obj) {
      let ctx = document.getElementById('myChart')
      console.log('차트시작~~')
      let myChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [this.$t('msg.SETT010T010.063'), this.$t('msg.SETT010T010.064')],
          datasets: [
            {
              backgroundColor: [
                '#1c69e3',
                '#a5c8fc'
              ],
              data: [obj.useLimit, obj.rstLimit]
            }
          ]
        },
        options: {
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltip, data) => {
                const inx = tooltip.index
                const datasets = data.datasets[0]
                return ' ' + this.$ekmtcCommon.changeNumberFormat(datasets.data[inx], { isComma: true })
              }
            }
          }
        }
      })
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFun () {
      this.$Simplert.close()
    },
    openPopup (compNm, kind) {
      console.log('openPopup')
      this.customComponent = compNm

      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    closePoup () {
      this.isShowPop = false
      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    }
  }
}

</script>

<style scoped>
  .text-column {
    text-align: left;
  }
  .number-column {
    text-align: right;
  }
</style>
